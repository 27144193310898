@import '../../shared/_variables.scss';

:root {
  --mobile-bg: #fff;
  --mobile-text: #272a39;
  --mobile-border: #eee;
  --mobile-hover: rgba(255, 255, 255, 0.1);
  --mobile-content-bg: #f6f7f9;
}

body.dark-mode {
  --mobile-bg: #1a1a1a;
  --mobile-text: #e0e0e0;
  --mobile-border: #333;
  --mobile-hover: rgba(255, 255, 255, 0.05);
  --mobile-content-bg: #121212;
}

.mobile-layout {
  min-height: 100vh;
}

.mobile-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 200px;
  z-index: 1030;
  transition: transform 0.3s ease-in-out !important;
  transform: translateX(-100%);
  border-right: 1px solid var(--mobile-border);
  background: var(--mobile-bg);
  box-shadow: 1px 0 2px rgba(0,0,0,.05);

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  &-close {
    position: absolute;
    right: 1rem;
    top: .25rem;
    font-size: 2rem;
    cursor: pointer;
  }

  .nav {
    margin-top: 3rem;
    gap: .5rem;
  }

  .nav-link {
    display: flex;
    align-items: center;
    gap: .75rem;
    font-size: 1.2rem;
    font-weight: 500;
    padding: .5rem 1rem;
    color: var(--mobile-text);
    
    &:hover {
      background: var(--mobile-hover);
    }
    
    &.active {
      color: $brand-success;
      font-weight: 500;

      .icon {
        background: $brand-success;
      }
    }

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &.show {
    transform: translateX(0);
  }
}

.mobile-logo {
  width: 148px;
  height: 20px;

  &-text {
    fill: var(--mobile-text);
  }
}

.mobile-header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1020;
  padding: 0.8rem 1rem;
  flex-direction: row;
  background: var(--mobile-bg);
  border-bottom: 1px solid var(--mobile-border);
  box-shadow: 0 1px 8px rgba(0,0,0,.1);

  .btn-link {
    padding: 0.375rem;
    &:hover {
      text-decoration: none;
      opacity: 0.8;
    }
  }

  .icon-menu-line {
    width: 20px;
    height: 20px;
  }
}

.mobile-main-content {
  position: relative;
  min-height: 100vh;
}

.mobile-content {
  padding-top: 5rem;
  padding-bottom: 5rem;
  min-height: calc(100vh - 56px);
  background: var(--mobile-content-bg);
}

.mobile-content-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.mobile-container-fullwidth {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  height: 100vh;
  height: -webkit-fill-available;
  min-height: -webkit-fill-available;
  overflow: hidden;
}