.fullscreen-loading-modal {
  .modal-content {
    background: #ffffff;
    border: none;
  }
}

.fullscreen-loading-overlay {
  background: transparent;
  
  .fullscreen-loading-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .fullscreen-loading-wrapper {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    .icon {
      margin-right: 0.5rem;
    } 

    .loading-message {
      min-width: 180px;
    }

    .loading-dots {
      width: 0.1875rem;
      &:after {
        content: '';
        animation: dots 1.5s steps(4, end) infinite;
      }
    }
  }
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

@keyframes dots {
  0%, 20% { content: ''; }
  40% { content: '.'; }
  60% { content: '..'; }
  80%, 100% { content: '...'; }
}

.dark-mode {
  .fullscreen-loading-modal {
    .modal-content {
      background: #2d2d2d;
    }
  }

  .fullscreen-loading-content {
    .loading-message {
      color: #ffffff;
    }
  }
}
