.mobile-chart-container {  
  .mobile-chart-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
    color: var(--mobile-text);

    .text-muted {
      font-size: 12px;
      color: var(--mobile-text-muted);
    }
  }
}