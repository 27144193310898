.table.batch-table {
  background: #FFFFFF;
  border: 1px solid #D9E0D5;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.09);
  position: relative; }
  .table.batch-table > thead > tr > th {
    font-size: 12px;
    font-weight: 600;
    border-bottom: 1px solid #d6ded1;
    text-align: center; }
    .table.batch-table > thead > tr > th.title {
      width: 30%; }
  .table.batch-table tr.child td.child {
    background-color: #f5f6f8;
    text-align: left;
    padding-top: 20px;
    padding-bottom: 20px; }
    .table.batch-table tr.child td.child .row {
      margin: 0 15px; }
    .table.batch-table tr.child td.child p {
      margin-bottom: .5rem; }
    .table.batch-table tr.child td.child .toggle-more {
      display: inline-block;
      margin-top: 20px;
      margin-bottom: 20px; }
    .table.batch-table tr.child td.child a {
      margin: 0;
      color: #545454; }
      .table.batch-table tr.child td.child a:hover, .table.batch-table tr.child td.child a:focus {
        color: #212121; }
  .table.batch-table td {
    vertical-align: middle;
    text-align: center;
    padding: 15px 10px; }
    .table.batch-table td a {
      color: #3c404c; }
    .table.batch-table td .warehouse-group {
      position: relative;
      width: 330%;
      margin-top: 1em;
      text-align: left; }
    .table.batch-table td .toggle-detail {
      margin: 0 10px 0 0;
      line-height: 1; }
    .table.batch-table td .media {
      flex: 1;
      margin-right: 15px; }
      .table.batch-table td .media img {
        max-width: 50px;
        max-height: 80px;
        margin: 0 auto; }
    .table.batch-table td .book-title {
      margin: 0;
      font-weight: normal;
      line-height: 1.3;
      text-align: left; }
    .table.batch-table td .action-controls {
      display: flex;
      gap: .7rem;
      align-items: center;
      justify-content: center; }
    .table.batch-table td .attribute-indicator > strong {
      display: inline-block; }
    .table.batch-table td .attribute-indicator-dot {
      font-size: 2rem;
      line-height: 0;
      vertical-align: middle;
      flex: 0 0 auto; }

.btn.btn-price-tracker {
  width: 40px;
  height: 40px;
  margin-right: 5px;
  background: #fff;
  text-align: center;
  padding: 5px;
  box-shadow: none;
  border: 1px solid #e0e3e5;
  border-radius: 3px; }
  .btn.btn-price-tracker.disabled_effect {
    filter: grayscale(90%); }

.toggle-more .caret {
  transition: all .3s ease-out;
  transform: rotate(-180deg); }

.toggle-more.collapsed .caret {
  transform: rotate(0); }

.title-link:hover, .title-link:focus {
  color: black; }

hr.separator-line {
  border-color: #D9E0D5; }

.shaking-button {
  animation: shake cubic-bezier(0.01, 0.07, 0.19, 0.97) both;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  transform: translate3d(0, 0, 0); }

@keyframes shake {
  10%, 90% {
    transform: translate3d(0, 0, 0); }
  20%, 80% {
    transform: translate3d(0, 0, 0); }
  30%, 50%, 70% {
    transform: translate3d(-2px, 0, 0); }
  40%, 60% {
    transform: translate3d(2px, 0, 0); } }

.custom-research-button {
  display: inline-block;
  position: relative;
  margin-bottom: .8rem; }
  .custom-research-button .icon-delete {
    position: absolute;
    top: -3px;
    right: 1px;
    width: 8px;
    height: 8px;
    background-color: #FF7158; }

.sequence-reminder {
  min-width: 12rem; }
  .sequence-reminder .form-control {
    border-left: 0; }
  .sequence-reminder .input-group-prepend .input-group-text {
    background-color: #fff;
    border-right: 0; }
  .sequence-reminder input {
    cursor: pointer; }

.inbound-steps {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-bottom: 1rem; }
  .inbound-steps .inbound-steps-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; }
    .inbound-steps .inbound-steps-item .step {
      padding: .1rem .8rem;
      border-radius: 2rem;
      background-color: #e0e0e0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #9f9f9f;
      font-size: 13px;
      font-weight: 500;
      white-space: nowrap;
      border: 2px solid transparent; }
    .inbound-steps .inbound-steps-item span {
      color: #666;
      font-size: 14px; }
    .inbound-steps .inbound-steps-item.active .step {
      border-color: rgba(61, 95, 192, 0.9);
      background-color: rgba(61, 95, 192, 0.1);
      color: rgba(61, 95, 192, 0.9); }
    .inbound-steps .inbound-steps-item.done .step {
      background-color: #00943E;
      color: #fff; }
  .inbound-steps .step-arrow {
    color: #d7d7d7;
    font-size: 20px; }
  @media (max-width: 1280px) {
    .inbound-steps {
      gap: 2px; }
      .inbound-steps .inbound-steps-item .step {
        padding: .1rem .5rem;
        font-size: 12px; }
      .inbound-steps .inbound-steps-item span {
        font-size: 12px; }
      .inbound-steps .step-arrow {
        font-size: 16px;
        margin: 0 2px; } }

.inbound-content-title {
  font-size: 1.1rem;
  font-weight: 500; }

.card-packing-group {
  border: 1px solid #e3e5ea; }
  .card-packing-group .card-header {
    font-size: .9rem;
    font-weight: 500;
    text-transform: uppercase;
    background-color: #e3e5ea; }
    .card-packing-group .card-header span {
      line-height: 1; }
  .card-packing-group .card-title {
    font-size: .9rem; }
  .card-packing-group .card-body {
    background-color: #f1f1f1; }
    .card-packing-group .card-body .product-thumbnail {
      width: 35px;
      height: 40px;
      object-fit: contain;
      transition: transform 0.2s ease, z-index 0s;
      position: relative; }
      .card-packing-group .card-body .product-thumbnail:hover {
        transform: scale(2);
        z-index: 100; }

.inbound-highlight-section {
  background-color: #f7f7f7; }

.card-inbound-plan {
  position: relative; }
  .card-inbound-plan > .card-header {
    padding: .6rem 1rem;
    background: #ebecf0;
    border: 1px solid #e1e2e5; }
  .card-inbound-plan .inbound-loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center; }
  .card-inbound-plan .inbound-loading-text {
    display: block;
    min-width: 255px;
    font-size: 1.2rem;
    font-weight: 600;
    color: #666; }

.inbound-step-number {
  font-size: 1.3rem;
  font-weight: 600;
  background-color: rgba(255, 193, 7, 0.2);
  color: #ffc107;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center; }

.loading-dots {
  display: inline-block; }
  .loading-dots::after {
    content: '';
    animation: dots 2s steps(4, end) infinite; }

@keyframes dots {
  0%, 20% {
    content: ''; }
  40% {
    content: '.'; }
  60% {
    content: '..'; }
  80%, 100% {
    content: '...'; } }

.restricted-items-panel {
  position: fixed;
  top: 3rem;
  right: 0;
  height: 100%;
  width: 400px;
  background: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transform: translateX(100%);
  transition: transform 0.3s ease;
  z-index: 1; }
  .restricted-items-panel.open {
    transform: translateX(0); }
  .restricted-items-panel .panel-header {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #eee;
    z-index: 1; }
  .restricted-items-panel .panel-header-text {
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 500; }
  .restricted-items-panel .panel-content {
    padding: 1rem;
    position: sticky;
    top: 2.5rem; }
  .restricted-items-panel .scroll-container {
    overflow-y: auto;
    height: calc(100vh - 5rem); }
  .restricted-items-panel .restricted-item {
    padding: 1rem;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    margin-bottom: 1rem; }
  .restricted-items-panel .item-details {
    display: flex;
    margin-bottom: 1rem; }
  .restricted-items-panel .item-image {
    width: 35px;
    height: auto;
    object-fit: contain;
    margin-right: 1rem; }
  .restricted-items-panel .item-info {
    flex: 1; }
  .restricted-items-panel .item-name {
    font-weight: 500;
    max-width: 21rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .restricted-items-panel .item-actions {
    display: flex;
    flex-direction: column;
    gap: .5rem; }
  .restricted-items-panel .item-sku, .restricted-items-panel .item-asin {
    font-size: 0.875rem;
    color: #6c757d; }
